<div infinite-scroll
     (nearBottom)="onNearBottomForBinsArea()"
     class="landing-scrollableView"
     #scrollableView>
  <div class="centered-content">
    <ng-container *ngIf="recentAssets.length">
      <h1>Recent videos</h1>
      <section  class='recent' [class.overflow-hidden]="scrubbingService.scrubbing$ | async" >
        <mam-recent-asset
          *ngFor="let asset of recentAssets; let index = index; trackBy: trackName"
          [asset]="asset"
          [routerLink]="['/asset', asset.name]"
          [queryParams]="{'type': 'recent'}"
          queryParamsHandling="merge"
          mam-firebase-ga="Open recent video"
          [mam-firebase-resource]="asset.name"
          
          [isClipbinsAvailable]="cachedBins && cachedBins.length>0"
        ></mam-recent-asset>
      </section>
    </ng-container>

    <ng-container *ngIf="bins$ | async as bins">
      <mat-card appearance="outlined" class="empty-bins" *ngIf="!bins.length; else displayBins">
        <img alt="Empty bins" src="images/empty_contents_logo.svg" />
        <div>
          <h1>You have no Clip Bins.</h1>
          <h2>Use the <span><mat-icon>add</mat-icon> New</span> button in the top
            left to create one and start collecting clips.</h2>
        </div>
      </mat-card>

      <ng-template #displayBins>
        <div class="clip-bin-bar">
          <h1 class="clip-bin-header">My clip bins</h1>
        </div>
        <section class="bins">
          <mam-clip-bin-preview *ngFor="let bin of bins; trackBy: trackName" [bin]="bin">
          </mam-clip-bin-preview>
        </section>
      </ng-template>
    </ng-container>
  </div>
</div>
