import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {BinService} from '../services/bin.service';
import {SnackBarService} from '../services/snackbar_service';
import {SharedLinkClipBinService} from '../shared_clipbin/services/shared_link_clipbin.service';

/** Clipbin rename dialog */
@Component({
  selector: 'mam-rename-bin-dialog',
  templateUrl: './rename_bin_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
    @import './shared/mixins';
    :host {
      max-width: 502px;
      width: 70vw;
      display: block;

        @include breakpoint-md-max {
          max-width: 95vw;
          width: 65vw;
          }

        @include breakpoint-sm-max {
          width: initial;
          max-width: initial;
        }
      }`
  ]
})
export class RenameBinDialog implements AfterViewInit {
  @ViewChild('input') input!: ElementRef;

  static readonly dialogOptions = {hasBackdrop: true};

  constructor(
      readonly dialogRef: MatDialogRef<RenameBinDialog>,
      private readonly snackBar: SnackBarService,
      private readonly binService: BinService,
      private readonly sharedLinkClipBinService: SharedLinkClipBinService,
      @Inject(MAT_DIALOG_DATA) public data: {title: string, name: string}) {
    dialogRef.afterClosed().subscribe((renamedTitle?: string) => {
      if (!renamedTitle?.trim() || renamedTitle === this.data.title) return;
      this.binService.rename(this.data.name, renamedTitle).subscribe({
        next: () => {
          this.updateClipBinSharedLinkTitle(encodeURIComponent(this.data.name),renamedTitle);
          this.snackBar.message('Clip bin has been renamed successfully.');
        },
        error: (error) => {
          this.snackBar.error(
              'Clip bin could not be renamed.', undefined, error);
        }
      });
    });
  }

  ngAfterViewInit() {
    // Select input on next tick to avoid an
    // `ExpressionChangedAfterItHasBeenCheckedError`, see
    // https://angular.io/errors/NG0100.
    setTimeout(() => {
      this.input.nativeElement.select();
    });
  }

  private updateClipBinSharedLinkTitle(name: string, newTitle: string) {
    this.sharedLinkClipBinService.updateClipBinSharedLinkTitle(name, newTitle);
  }
}
