import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {BinService} from '../services/bin.service';
import {SnackBarService} from '../services/snackbar_service';

/** Clipbin deletion dialog */
@Component({
  selector: 'mam-delete-bin-dialog',
  templateUrl: './delete_bin_dialog.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteBinDialog implements OnInit {
  static readonly dialogOptions = {
    hasBackdrop: true,
  };

  constructor(
      readonly dialogRef: MatDialogRef<DeleteBinDialog>,
      private readonly snackBar: SnackBarService,
      private readonly binService: BinService,
      @Inject(MAT_DIALOG_DATA) public data: {name: string}) {}

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe((deletionConfirmed) => {
      if (!deletionConfirmed) return;
      this.binService.delete(this.data.name).subscribe({
        next: () => {
          this.snackBar.message('Clip bin has been deleted successfully.');
        },
        error: (error) => {
          this.snackBar.error(
              'Clip bin could not be deleted.', undefined, error);
        }
      });
    });
  }
}
