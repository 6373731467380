import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { ScrubbingService } from 'shared/scrubbing_service';

import { AnalyticsEventType, FirebaseAnalyticsService } from '../firebase/firebase_analytics_service';
import { Asset, AssetService, Original } from '../services/asset_service';
import { BinService, BinWithClips } from '../services/bin.service';
import { MediaCacheService } from '../services/media_cache_service';
import { SearchInputService, SearchType } from '../services/search_input_service';
import { SnackBarService } from '../services/snackbar_service';
import { HomeView, StateService } from '../services/state_service';

/**
 * Default view of the home page
 */
@Component({
  selector: 'mam-landing',
  templateUrl: './landing.ng.html',
  styleUrls: ['./landing.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Landing implements OnInit, OnDestroy {
  @ViewChild('scrollableView') scrollableView!: ElementRef<HTMLElement>;

  /** The 3 most recently accessed clips */
  recentAssets: Original[] = [];

  bins$ = new ReplaySubject<BinWithClips[]>(1);
  readonly HomeView = HomeView;
  private readonly destroyed$ = new ReplaySubject<void>(1);
  
  constructor(
      private readonly assetService: AssetService,
      private readonly binService: BinService,
      private readonly cdr: ChangeDetectorRef,
      private readonly snackBar: SnackBarService,
      private readonly ngZone: NgZone,
      searchInputService: SearchInputService,
      analyticsService: FirebaseAnalyticsService,
      readonly stateService: StateService,
      readonly mediaCache: MediaCacheService,
      readonly scrubbingService: ScrubbingService,
  ) {
    analyticsService.logEvent('Visited landing', {
      eventType: AnalyticsEventType.NAVIGATION,
      path: '/',
      string2: '/',
    });

    searchInputService.searchType$.next(SearchType.VOD);

    // Resets pagination when a bin is created
    this.binService.binsUpdated$.pipe(takeUntil(this.destroyed))
        .subscribe(() => {
          this.resetBinsPagination();
          this.scrollToTop();
        });
  }

  ngOnInit() {
    // Observe periodic updates outside of NgZone so that e2e tests are not
    // stuck waiting for this observable to complete.
    this.ngZone.runOutsideAngular(() => {
      this.assetService.watchRecents()
          .pipe(takeUntil(this.destroyed))
          .subscribe(assets => {
            this.ngZone.run(() => {
              if (!assets) return;

              this.recentAssets = assets;
              // Save recent assets for navigating between them
              // when we open the details page.
              this.assetService.cachedRecents = assets;

              this.cdr.detectChanges();
            });
          });
    });

    combineLatest([this.binService.binsUpdated$, this.binsNextPageChange$])
        .pipe(
            takeUntil(this.destroyed), tap(() => {
              this.isBinsLoading = true;
            }),
            switchMap(
                () => this.binService.listWithAssets(this.binsNextPageToken)))
        .subscribe((response) => {
          this.cdr.markForCheck();

          this.isBinsLoading = false;

          if (!response) {
            this.snackBar.error('Clip bins could not be loaded');
            return;
          }

        const { binsWithAssets, nextPageToken } = response;

          if (!nextPageToken) {
            this.listenToBinsNextPageChange = false;
          }

          this.cachedBins.push(...binsWithAssets);
          this.bins$.next(this.cachedBins);
          this.binsNextPageToken = nextPageToken;
        });
  }


  trackName(index: number, value: BinWithClips | Asset) {
    return value.name;
  }

  onNearBottomForBinsArea() {
    // Only get triggered when API finishes
    // or it is not the last page result of the API results
    if (!this.isBinsLoading && this.listenToBinsNextPageChange) {
      this.binsNextPageChange$.next();
    }
  }

  ngOnDestroy() {
    // Unsubscribes all pending subscriptions.
    this.destroyed.next();
    this.destroyed.complete();
  }

  /** Handles on-destroy Subject, used to unsubscribe. */
  private readonly destroyed = new ReplaySubject<void>(1);

  // Pagination related
  public cachedBins: BinWithClips[] = [];
  private readonly binsNextPageChange$ = new BehaviorSubject<void>(undefined);
  private binsNextPageToken?: string;
  private isBinsLoading = false;
  private listenToBinsNextPageChange = true;

  private resetBinsPagination() {
    this.cachedBins = [];
    this.binsNextPageToken = undefined;
    this.listenToBinsNextPageChange = true;
  }

  private scrollToTop() {
    if (this.scrollableView) {
      this.scrollableView.nativeElement.scrollTop = 0;
    }
  }
}
